import {
    useNavigate
} from "react-router-dom";

import {
    Button,
    Container
} from 'react-bootstrap';

const HomePage = () => {
    const navigate = useNavigate();

    return <>
        <Container style={{margin: 20}}>

            <div style={{display: "flex", alignItems: "center"}}>
                <img src="/icon-192x192.png" alt="AI Recipe Explorer Logo" width={100}/>
                <h2 className="display-3" style={{marginLeft: 20}}>
                    AI Recipe Explorer
                </h2>
            </div>

            <p>
                <a href='https://apps.apple.com/us/app/ai-recipe-explorer/id1664972944'><img
                    width={150} alt='Get it on App Store'
                    src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us'/></a>
                <a href='https://play.google.com/store/apps/details?id=sapience.ai.recipeexplorer&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                    width={190} alt='Get it on Google Play'
                    src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
            </p>
            <p className="lead">
                <p>
                    The Ai Recipe Explorer app is powered by Artificial Intelligence, which allows you to fully
                    customize and influence your recipe!
                </p>
                <p>
                    Do you want a yellow colored, spicy, gluten free, keto, pizza, deep dish, with unusual toppings, and
                    made in a dutch oven? The Ai Recipe Explorer app will find and customize the recipe for you! Need
                    the recipe formatted as html, json, markdown, etc? Just add it under additional recipe info, easy!
                    No more endless web searches, dusting off cookbooks, or scrolling through ad ridden food blogs!
                </p>
                <p>
                    Recipe Results Include:
                    <ul>
                        <li>Recipe Name</li>
                        <li>Recipe Name</li>
                        <li>Prep time</li>
                        <li>Cook time</li>
                        <li>Servings</li>
                        <li>Summary</li>
                        <li>Flavor Profile</li>
                        <li>Ingredients</li>
                        <li>Instructions</li>
                        <li>Nutritional Facts</li>
                        <li>Macros</li>
                        <li>Estimated Cost</li>
                    </ul>
                </p>

                <p>
                    A number of AI powered tools are also available such as:
                    <ul>

                    </ul>
                    <li>Ingredient explanations (ex: "what is spirulina?")</li>
                    <li>Unit conversions (ex: "convert 4 cups to tablespoons")</li>
                    <li>Ingredient Substitutions (ex: "In banana bread, subsitute butter and eggs")</li>
                </p>
                <p>
                    Easily copy recipe text, share, print, and is compatible with any existing recipe managers!
                </p>
                <p>
                    Many more features are coming soon!
                </p>
            </p>
            <hr className="my-3"/>

            {/*<p className="lead">*/}
            {/*    <Button*/}
            {/*        color="primary"*/}
            {/*        onClick={() => {*/}
            {/*            window.open("https://airecipeexplorer.app");*/}
            {/*        }}>*/}
            {/*        Go to AI Recipe Explorer*/}
            {/*    </Button>*/}
            {/*</p>*/}
            <p>
                <Button
                    color="primary"
                    onClick={() => {
                        navigate(
                            "/PrivacyPolicy"
                        );
                    }}>
                    Privacy Policy
                </Button>
            </p>
            <p>
                <Button
                    color="primary"
                    onClick={() => {
                        navigate(
                            "/Terms"
                        );
                    }}>
                    Terms Of Service
                </Button>
            </p>
            <p>
                <Button
                    color="primary"
                    onClick={() => {
                        navigate(
                            "/Support"
                        );
                    }}>
                    Support
                </Button>
            </p>
        </Container>
    </>;
};

export default HomePage;