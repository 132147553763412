class RecipeModel {

    constructor(json) {
        if (!json) json = {};

        this.id = json.id;
        this.RecipeText = json.recipeText || '';
        this.RequestText = json.requestText || '';
        this.RequestMessageType = json.requestMessageType || 0;
        this.Timestamp = json.timestamp || '';
        this.RequestIngredients = json.requestIngredients;
        this.RequestAdditional = json.requestAdditional;
    }

    static fromJson(json) {
        return new RecipeModel(json);
    }
}

export default RecipeModel;