import {
    Container
} from "react-bootstrap";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ApiService from "../services/ApiService";

const Recipe = () => {
    const {shareCode} = useParams();
    const [recipe, setRecipe] = useState(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) return;

        ApiService.redeemShareCode(shareCode).then(r => {
            console.log(r);
            ApiService.getRecipe(r.userId, r.recipeId).then((recipe) => {
                setRecipe(recipe);
                setLoaded(true);
            });
        });
    });


    if (recipe) {
        return <Container style={{margin: 20}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <img src="/icon-192x192.png" alt="AI Recipe Explorer Logo" width={100}/>
                <h2 className="display-3" style={{marginLeft: 20}}>
                    AI Recipe Explorer
                </h2>
            </div>

            <p>
                <a href='https://apps.apple.com/us/app/ai-recipe-explorer/id1664972944'><img
                    width={150} alt='Get it on App Store'
                    src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us'/></a>
                <a href='https://play.google.com/store/apps/details?id=sapience.ai.recipeexplorer&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                    width={190} alt='Get it on Google Play'
                    src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
            </p>


            <h1 style={{marginBottom: 20, marginTop: 20}}>
                {recipe.RequestText}
            </h1>

            <p>
                {recipe.RecipeText.trim('\n').split('\n').map(str => <>{str}<br/></>)}
            </p>
        </Container>
    }

    return <Container></Container>;
}

export default Recipe;