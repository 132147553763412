class ShareCodeModel {
    constructor(json) {
        if (!json) json = {};

        this.userId = json.userId;
        this.recipeId = json.recipeId || '';
    }

    static fromJson(json) {
        return new ShareCodeModel(json);
    }
}

export default ShareCodeModel;