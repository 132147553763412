import {Container} from "react-bootstrap";

const Support = () => {
    return (
        <>
            <Container>
                <h1>Support</h1>

                <p>For support, please contact us at <a
                    href="mailto:contact@recipeexplorer.ai">contact@recipeexplorer.ai</a></p>
            </Container>
        </>
    );
}

export default Support