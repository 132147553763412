import {
    Container
} from "react-bootstrap";

const PrivacyPolicy = () => {
    return (
        <>
            <Container>
                <h1>Privacy Policy</h1>
                <p>
                    This privacy policy applies to the AI Recipe Explorer App, which is a mobile, web, and macOS
                    application that allows users to search for and adjust recipes using artificial intelligence. The
                    app is operated by Sapience Software LLC.
                </p>
                <h5>Information Collection and Use</h5>
                <p>
                    The only information we collect from users is your email address. This information is used solely
                    for the purpose of account and subscription related management and emailing. We do not share, sell,
                    or otherwise disclose email addresses to any third parties.
                </p>
                <h5>Security</h5>
                <p>
                    We take the security of your information seriously and have implemented appropriate technical and
                    organizational measures to protect your email address from unauthorized access, use, disclosure, or
                    destruction.
                </p>
                <h5>Changes to This Privacy Policy</h5>
                <p>
                    We may update this privacy policy from time to time. We will notify you of any changes by posting
                    the new privacy policy on this page. You are advised to review this privacy policy periodically for
                    any changes.
                </p>

                <h5>Contact Us</h5>

                <p>If you have any questions or concerns about our privacy policy, please email us at
                    <a href="mailto:contact@recipeexplorer.ai"> contact@recipeexplorer.ai</a>
                </p>

                <p>
                    This privacy policy was last updated on 1/18/2023
                </p>
            </Container>
        </>
    );
}

export default PrivacyPolicy;