import {
    Container
} from "react-bootstrap";

const TermsOfUse = () => {
    return (
        <>
            <Container>
                <h4>By using the AI Recipe Explorer app, you agree to the following terms of service:</h4>

                <p>AI Recipe Explorer is a service powered by artificial intelligence. It allows users to search for,
                    discover, and adjust recipes, plus features to help assist in the kitchen.</p>

                <p>AI Recipe Explorer is intended to be used within the context of food. Do not use AI Recipe Explorer
                    for any other purpose.</p>

                <p>We do not guarantee the accuracy, completeness, or quality of the results provided by our app. It is
                    the user's responsibility to ensure that foods and ingredients are safe to consume and handle.</p>

                <p>AI Recipe Explorer is not responsible for any allergies or dietary restrictions that may be triggered
                    by the use of our service. It is the responsibility of the user to carefully read and understand the
                    ingredient list and to check for any potential allergens or other dietary considerations.</p>

                <p>AI Recipe Explorer is not responsible for any damages, injuries, or losses that may result from the
                    use of our service. By using AI Recipe Explorer, you agree to indemnify and hold us harmless from
                    any claims, damages, or expenses that may arise from the use of our service.</p>

                <p>AI Recipe Explorer reserves the right to modify or discontinue the app or any of its features at any
                    time without notice.</p>

                <p>AI Recipe Explorer is provided "as is" without warranties of any kind, either express or implied.</p>

                <p>These terms of service constitute the entire agreement between you and AI Recipe Explorer and
                    supersede any prior agreements or understandings, whether written or oral.</p>

                <p>By using AI Recipe Explorer, you acknowledge that you have read and understand these terms of service
                    and agree to be bound by them. If you do not agree to these terms, please do not use our app.</p>

            </Container>
        </>
    );
}

export default TermsOfUse;