import RecipeModel from "../models/RecipeModel";
import ShareCodeModel from "../models/ShareCodeModel";

class ApiService {
    static Rm;
    static shareCode;

    static async getRecipe(userId, recipeId) {
        if (ApiService.Rm != null)
            return ApiService.Rm;

        return fetch(`https://sapienceaifunctionapp.azurewebsites.net/api/users/${userId}/recipes/${recipeId}`)
            .then(response => {
                return response.json().then(data => {
                    ApiService.Rm = RecipeModel.fromJson(data);
                    console.log(ApiService.Rm);

                    return ApiService.Rm;
                });
            });
    }

    static async redeemShareCode(shareCode) {
        if (ApiService.shareCode != null)
            return ApiService.shareCode;

        return fetch(`https://sapienceaifunctionapp.azurewebsites.net/api/shared/${shareCode}`)
            .then(response => {
                return response.json().then(data => {
                    ApiService.shareCode = ShareCodeModel.fromJson(data);
                    console.log(ApiService.shareCode);

                    return ApiService.shareCode;
                });
            });
    }
}

export default ApiService;