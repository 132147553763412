import {
    Route,
    Routes
} from "react-router-dom";
import './App.css';
import PrivacyPolicy
    from "./pages/PrivacyPolicy";
import Home
    from "./pages/Home";
import TermsOfUse from "./pages/TermsOfUse";
import Support from "./pages/Support";
import Recipe from "./pages/Recipe";

function App() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route
                    path="/PrivacyPolicy"
                    element={
                        <PrivacyPolicy/>}/>
                <Route
                    path="/Privacy"
                    element={
                        <PrivacyPolicy/>}/>
                <Route
                    path="/Terms"
                    element={
                        <TermsOfUse/>}/>
                <Route
                    path="/Support"
                    element={
                        <Support/>}/>
                <Route
                    path="/Recipes/:shareCode"
                    element={
                        <Recipe/>}/>
            </Routes>
        </>
    );
}

export default App;
